<template>
	<!-- 任务1 -->
	<el-form :model="form" ref="form" :rules="formRules" v-loading="loading" class="formBox">
		<div style="background: #fff;padding: 15px;margin-top: 10px;">
			<div style="border-bottom: 1px solid #F0F2F5;padding-bottom: 15px;margin-bottom: 20px;">基本信息</div>
			<div style="margin-left:30px">

				<el-form-item label="级别名称 :" label-width="100px" prop="VipRoleName">
					<input type="text" style="width:400px;height:30px;padding-left:10px;border:1px solid #ccc" placeholder="最多输入9个汉字或18个英文字母"
					 v-model="form.VipRoleName" class="edit-input" ref="Title" @input="changeValue">
					<!-- <el-input auto-complete="on" placeholder="最多输入9个汉字或18个英文字母" clearable maxlength="9" v-model="form.VipRoleName" style="width: 300px;"></el-input> -->
				</el-form-item>
				<el-form-item label="会员卡背景 :" label-width="100px" :prop="form.BackgroupType==1?'BackgroupImg':'nocheck'">

					<el-radio-group v-model="form.BackgroupType">
						<div style="display: flex;align-items: center;height: 45px;">
							<el-radio :label="0">背景颜色</el-radio>
							<el-color-picker v-if="form.BackgroupType=='0'" v-model="form.BackgroupColor" size="mini"></el-color-picker>
						</div>

						<div style="display: flex;align-items: center;height: 45px;margin-bottom: 30px;">
							<el-radio :label="1">背景图片</el-radio>
							<div class="Imgslist" style="position: relative;">
								<el-upload v-if="form.BackgroupType=='1'" style="width: 135px;" :action="imgApi" list-type="picture-card"
								 :on-success="handleAvatarSuccessfu" :file-list="fileList" accept="image/png,image/jpeg,image/jpg,image/gif"
								 :show-file-list="false">
									<div>
										<div style="color: ##909399;font-size: 12px;width:135px;height: 30px;">上传图片</div>
									</div>
								</el-upload>

							</div>

						</div>
						<img :src="imgUrl+form.BackgroupImg" alt="" class="imgposition" style="width:200px;height:200px;display: block;"
						 v-if="form.BackgroupImg&&form.BackgroupType=='1'">

					</el-radio-group>

				</el-form-item>

				<!-- <el-form-item v-if="form.VipLevel==1" label="升级条件 :" label-width="100px" required :prop="uprankType==0?'PayMoney':'GrowthValue'">
					<div>
						<el-radio v-model="uprankType" :label="0" @change="uprankChange">付款购买</el-radio>
						<span v-if="uprankType==0">
							<input v-model="form.PayMoney" style="margin-right: 10px; padding:0 10px;width:150px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
							 type="number" @keydown="handleInput2">元
						</span>
					</div>
					<div style="display: flex;align-items: center;margin-top: 20px;">
						<el-radio v-model="uprankType" :label="1" @change="uprankChange">累加成长值</el-radio>
						<span v-if="uprankType==1">
							<input v-model="form.GrowthValue" style="margin-right: 10px; padding:0 10px;width:150px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
							 oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')" type="number">成长值</input>
						</span>
					</div>
				</el-form-item> -->
				
				<!-- v-if="form.VipLevel!=1" -->
				<el-form-item  label="升级条件 :" label-width="100px" required prop="GrowthValue">

					<div style="display: flex;align-items: center;">累加成长值
						<input v-model="form.GrowthValue" style="margin:0 10px; padding:0 10px;width:150px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
						 oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')" type="number">成长值

						</input>
					</div>
				</el-form-item>


			</div>
		</div>

		<!-- 任务2 -->
		<div style="background: #fff;padding: 15px;margin-top: 10px;">
			<div style="border-bottom: 1px solid #F0F2F5;padding-bottom: 15px;">级别权益</div>

			<div style="margin-left:50px">
				<el-form-item :prop="checked1?'VipDiscount':'nocheck'">
					<el-checkbox v-model="checked1" style="margin: 15px 10px 0 0;" @change="discountChange">消费折扣</el-checkbox>
					<span v-if="checked1">
						<input v-model="form.VipDiscount" style="margin-right:10px;padding:0 10px;width:150px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
						 type="number" @keydown="handleInput">折</input>
					</span>
				</el-form-item>

				<el-checkbox v-model="form.IsFreeExpress" style="margin-top: 20px;">包邮</el-checkbox></br>

				<el-form-item :prop="checked3?'PointMultiple':'nocheck'">
					<el-checkbox v-model="checked3" style="margin-top: 20px;" @change="multChange">积分多倍</el-checkbox>
					<span v-if="checked3">
						<!-- <input v-model="form.PointMultiple" style="margin:0 10px;padding:0 10px;width:150px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
						 oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')" type="number"> -->
						 <input v-model="form.PointMultiple" style="margin:0 10px;padding:0 10px;width:150px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
						  type="number" @keydown="handleInput"></input>倍
					</span>

					<div style="color: #909399;font-size: 12px;margin-left: 24px;margin-top: 5px;">设置积分多倍，赠送积分时均乘倍发放，包括:满减送积分、积分管理中配置的赠送积分规则；不包括后台客户查询中直接赠送积分</div>
				</el-form-item>
			</div>


		</div>

		<!-- 任务3 -->
		<div style="background: #fff;padding: 15px;margin-top: 10px;margin-bottom: 120px;">
			<div style="border-bottom: 1px solid #F0F2F5;padding-bottom: 15px;">升级礼包<span style="color: #909399;font-size: 12px;margin-left: 30px;">在客户升级到该等级时发放，一次升级仅发放一次</span></div>
			<div style="margin-left:50px">

				<el-form-item :prop="package1?'CurrentAuthGivePoint':'nocheck'">
					<el-checkbox v-model="package1" style="margin-top: 15px;" @change="pointeChange">送积分</el-checkbox>
					<span v-if="package1">
						<input v-model="form.CurrentAuthGivePoint" style="margin:0 10px;padding:0 10px;width:150px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
						 oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')" type="number">积分
					</span>
				</el-form-item>

				<el-checkbox v-model="package2" style="margin-top: 20px;">送优惠券</el-checkbox>
				<span class="selectGoods" @click="selsectCoup" v-if="package2">选择优惠券</span>
				<div style="color: #909399;font-size: 12px;margin-left: 24px;margin-top: 5px;">会员升级赠送优惠券，领取次数不受限制；若优惠券剩余数量为0或少于赠送数量，将不再发放。请关注优惠券剩余数量并及时补充</div>

				<!-- 选择优惠券的商品 -->
				<div style="width:1000px;margin:20px 0 50px" v-if="showCouponList.length&&package2">
					<el-table :data="showCouponList" style="width:1000px">
						<el-table-column prop="Name" label="优惠券">
							<template slot-scope="scope">
								<div>{{scope.row.CouponName}}</div>
								<div v-if="scope.row.LeaveCount==0" style="color:red">数量=0</div>
								<div v-if="!scope.row.IsOpen" style="color:red">已失效</div>
							</template>
						</el-table-column>
						<el-table-column prop="UseTypeValue" label="类型">
						</el-table-column>
						<el-table-column prop="CouponContent" label="优惠内容" width="170">
						</el-table-column>
						<el-table-column prop="ActivityRangeTypeValue" label="适用商品">
						</el-table-column>
						<el-table-column prop="Stock" label="赠送数量">
							<template slot-scope="scope">
								<input v-model="scope.row.Count" style="margin:0 15px;padding:0 10px;width:100px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
								 oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')" type="number">
								</input>

							</template>

						</el-table-column>
						<el-table-column label="操作" width="120">
							<template slot-scope="scope">
								<span style="color:red;cursor: pointer;" @click="delectContect(scope.row,scope.$index)">删除</span>
							</template>
						</el-table-column>
					</el-table>

					<el-pagination v-if="TotalNum" style="margin-top:20px;float:right;" @size-change="handleSizeChangenum"
					 @current-change="handleCurrentChangenum" :current-page="currentPagenum" :page-sizes="[5, 10,15,20,25]" :page-size="pageSizenum"
					 layout="total, sizes, prev, pager, next, jumper" :total="TotalNum">
					</el-pagination>
				</div>

				<el-checkbox v-model="package3" style="margin-top: 20px;">送商品</el-checkbox>
				<span class="selectGoods" style="margin-left:40px;" @click="handleSelectProDialog" v-if="package3">选择商品</span>
				<div style="color: #909399;font-size: 12px;margin-left: 24px;margin-top: 5px;">若商品库存=0或少于赠送数量，将不再发放赠品。请关注商品库存并及时补充</div>

				<!-- 选择商品的商品 -->
				<div style="width:1000px;margin:20px 0 50px" v-if="showProductSpecList.length&&package3">
					<el-table :data="showProductSpecList" style="width:1000px">
						<el-table-column prop="date" label="商品" width="250">
							<template slot-scope="scope">
								<div class="product-info">
									<img v-if="scope.row.ImgUrl" :src="imgUrl+scope.row.ImgUrl">
									<svg-icon v-else icon-class="noImgIcon" />
									<div>
										<div style="display:flex;justify-content:space-between;align-items:center;">
											<div>
												<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
											</div>
										</div>
										<div v-if="!scope.row.Stock" style="color:red">已售罄</div>
										<div v-if="!scope.row.IsOpen" style="color:red">已下架</div>
									</div>
								</div>
							</template>

						</el-table-column>
						<el-table-column prop="Spec" label="规格">
						</el-table-column>
						<el-table-column prop="ProductPrice" label="价格">
						</el-table-column>
						<el-table-column prop="Stock" label="库存">
						</el-table-column>
						<el-table-column prop="Stock" label="赠送数量">
							<template slot-scope="scope">
								<input @input="inputsChange(scope.row)" v-model="scope.row.Count" style="margin:0 15px;padding:0 10px;width:100px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
								 oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')" type="number">
								</input>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="120">
							<template slot-scope="scope">
								<span style="color:red;cursor: pointer;" @click="delectContect1(scope.row,scope.$index)">删除</span>
							</template>
						</el-table-column>
					</el-table>

					<el-pagination v-if="TotalNum1" style="margin-top:20px;float:right;" @size-change="handleSizeChangenum1"
					 @current-change="handleCurrentChangenum1" :current-page="currentPagenum1" :page-sizes="[5, 10,15,20,25]"
					 :page-size="pageSizenum1" layout="total, sizes, prev, pager, next, jumper" :total="TotalNum1">
					</el-pagination>
				</div>


			</div>


			<!-- 选择商品弹框 -->
			<el-dialog title="选择商品" :visible.sync="selectProDialog" width="70%" class="dialog">
				<select-produce api="memberVipRoleProduct" :couponData="couponData" :placeholderNum="placeholdeString" :params="['Keywords', 'ProductType', 'ProductGroup', 'ProductBrand', 'IsSelectable','ActivityCouponId','AcctivityRangeType',]"
				 :selectedData="form.ProductSpecList" @getSelectList="getSelectList" :isShowSpecValue="selectProDialog"
				 :visible.sync="selectProDialog" v-if="selectProDialog" :isShowCheck='true'></select-produce>
			</el-dialog>

			<!-- 选择优惠券弹框 -->
			<el-dialog title="选择优惠券" :visible.sync="coupDialog" width="70%" class="dialog">
				<div class="filter-container title-border">
					<!-- 关键字搜索 -->
					<div class="filter-item">
						<label class="label">关键字: </label>
						<el-input v-model="counpKeys" style="width: 200px;" placeholder="优惠券名称" clearable></el-input>
					</div>

					<div class="filter-item">
						<label class="label">优惠卷类型: </label>
						<el-select v-model="UseType" style="width: 200px;" placeholder="请选择">
							<el-option label="全部" :value="null"></el-option>
							<el-option v-for="(item,index) in UseTypeList" :key="index" :label="item.val" :value="item.id">
							</el-option>
						</el-select>
					</div>

					<div class="filter-item">
						<el-button type="primary" @click="coupFilter">查询</el-button>
					</div>

				</div>

				<div class="table-container content">
					<el-table :data="tableDataList" v-loading="loading" style="width: 100%" ref="compSelectProTable" :row-key="getRowKeys"
					 @selection-change="handleSelectionChange">
						<el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
						<el-table-column prop="CouponName" label="优惠券"></el-table-column>
						<el-table-column prop="UseTypeValue" label="类型"></el-table-column>
						<el-table-column prop="CouponContent" label="优惠内容"></el-table-column>
						<el-table-column prop="ActivityRangeTypeValue" label="适用商品"></el-table-column>
						<el-table-column prop="ReceiveLimitValue" label="限领次数"></el-table-column>
						<el-table-column prop="LeaveCount" label="剩余数量"></el-table-column>
					</el-table>

					<div style="text-align: right;margin-top:20px;">
						<el-pagination v-if="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
						 :page-sizes="[5,10, 15, 20, 25, 30]" :page-size="size" layout="total, sizes, prev, pager, next, jumper" :total="total">
						</el-pagination>
					</div>

					<div style="text-align: center;margin-top:20px;">
						<span style="margin:0 10px">已选中<span style="color:red;margin:0 5px">{{selectNumber}}</span>张</span>
						<el-button type="primary" @click="sureSelectcoup" :laoding="loading">确定</el-button>
					</div>

				</div>

			</el-dialog>
		</div>

		<!-- 底部框 -->
		<div class="footerBar">
			<el-button style="width: 120px;height: 35px;" @click="cancelBtn">取消</el-button>
			<el-button :loading="loading" style="width: 120px;margin-left: 50px;height: 35px;" type="primary" @click="saveBtn('form')">保存</el-button>
		</div>
	</el-form>


</template>

<script>
	import {
		memberVipRoleInfo,
		activitycoupoList,
		activitycoupoSave,

	} from '@/api/goods'
	import selectProduce from '@/components/SelectMulProduce'

	import config from '@/config/index'
	export default {
		components: {
			selectProduce
		},
		data() {
			var checkImg = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请上传会员卡背景图'));
				} else {
					return callback();
				}
			};
			var checkno = (rule, value, callback) => {
				return callback();
			};

			var checkpaymoney = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请输入付款购买金额'));
				} else {
					return callback();
				}
			};
			var checkGrowth = (rule, value, callback) => {

				if (!value && value !== 0) {
					return callback(new Error('请输入累加成长值'));
				} else {
					if (value > 100000000) {
						return callback(new Error('上限值最大值为100000000'));
					} else {
						return callback();
					}
				}
			};
			var checkVipDiscount = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请输入消费折扣'));
				} else {
					if (value < 1 || value > 9.9) {
						return callback(new Error('消费折扣需在1~9.9范围内'));
					} else {
						callback();
					}
				}
			};
			var checkPointMultiple = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请输入积分倍数'));
				}else if(value<1){
					return callback(new Error('积分倍数请输入大于1的数'));
				}else {
					callback();
				}
			};

			var checkCurrentAuthGive = (rule, value, callback) => {
				console.log(value, '898989')
				if (!value) {
					return callback(new Error('请输入积分'));
				} else {
					callback();
				}
			};


			return {
				origionGrowthValue:0,
				placeholdeString: '商品名称、编码、规格条形码',
				uprankType: 0,
				showCouponList: [],
				goodsDatas: [],
				TotalNum1: 0,
				currentPagenum1: 1,
				pageSizenum1: 5,
				selectCoupDatas: [],
				TotalNum: 0,
				currentPagenum: 1,
				pageSizenum: 5,
				loading: false,
				total: 0,
				current: 1,
				size: 10,
				tableDataList: [],
				UseType: null,
				UseTypeList: [{
					id: 0,
					val: '满减券'
				}, {
					id: 1,
					val: '打折券'
				}, {
					id: 2,
					val: '随机金额'
				}],
				counpKeys: '',
				couponData: {
					id: '',
					range: 0
				},
				coupDialog: false,
				selectProDialog: false,
				fileList: [],
				form: {
					VipRoleName: '',
					BackgroupType: 0,
					BackgroupImg: '',
					BackgroupColor: '#409EFF',
					PayMoney: null,
					IsUsePay: 0,
					GrowthValue: null,
					VipDiscount: null,
					IsFreeExpress: false,
					PointMultiple: null,
					CurrentAuthGivePoint: null,
					ProductSpecList: [],
					CouponList: [],
					IsUseGrowthValue: false,
				},
				formRules: {
					VipRoleName: [{
						required: true,
						message: '请输入级别名称',
						trigger: 'blur'
					}],
					BackgroupType: [{
						required: true,
						message: '请选择会员卡背景',
						trigger: 'change'
					}],
					BackgroupImg: [{
						validator: checkImg,
						trigger: 'blur'
					}],
					nocheck: [{
						validator: checkno,
						trigger: 'blur'
					}],
					PayMoney: [{
						validator: checkpaymoney,
						trigger: 'blur'
					}],
					GrowthValue: [{
						validator: checkGrowth,
						trigger: 'blur'
					}],
					VipDiscount: [{
						validator: checkVipDiscount,
						trigger: 'blur'
					}],
					PointMultiple: [{
						validator: checkPointMultiple,
						trigger: 'blur'
					}],
					CurrentAuthGivePoint: [{
						validator: checkCurrentAuthGive,
						trigger: 'blur'
					}],

				},
				taskPoints: '',
				checked1: false,
				checked2: false,
				checked3: false,
				package1: false,
				package2: false,
				package3: false,
				vipPayPrice: '',
				carId: null,
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				multipleSelection: [],
				selectNumber: 0,
				showProductSpecList: [],
				VipLevel: null,
				startProductData: [],
				lastSubmitProduct:[]
			};
		},
		beforeMount() {
			this.carId = this.$route.query.Id
			this.VipLevel = this.$route.query.VipLevel
			this.form.VipLevel = this.$route.query.VipLevel
			this.getDatas()

		},
		methods: {
			inputsChange(record){
				console.log(record,123)
				console.log(this.form.ProductSpecList,456)
			},
			changeValue() {
				let leng = this.validateTextLength(this.form.VipRoleName)
				if (leng >= 9) {
					this.$refs.Title.maxLength = leng
				} else {
					this.$refs.Title.maxLength = 18
				}
			},
			validateTextLength(value) {
				// 中文、中文标点、全角字符按1长度，英文、英文符号、数字按0.5长度计算
				let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g

				let mat = value.match(cnReg)
				let length
				if (mat) {
					length = (mat.length + (value.length - mat.length) * 0.5)
					return length
				} else {
					return value.length * 0.5
				}
			},

			// 消费折扣
			discountChange() {
				if (!this.checked1) {
					this.form.VipDiscount = null;
				}
			},
			// 积分多倍
			multChange() {
				if (!this.checked3) {
					this.form.PointMultiple = null;
				}
			},
			// 送积分
			pointeChange() {
				if (!this.package1) {
					this.form.CurrentAuthGivePoint = null;
				}
			},
			// 升级条件改变
			uprankChange() {
				if (this.uprankType == 0) {
					this.form.GrowthValue = null
				} else {
					this.form.PayMoney = null
				}
			},

			// 获取数据
			async getDatas() {
				try {
					this.loading = true;

					if (this.carId > 0) {
						let result = await memberVipRoleInfo({
							Id: this.carId
						})
						this.form = result.Result

						if (this.form.IsUsePay) {
							this.uprankType = 0
						} else {
							this.uprankType = 1
						}

						if (this.form.VipDiscount&&this.form.VipDiscount!=10) {
							this.checked1 = true
						}else{
							this.form.VipDiscount=null
							this.checked1 =false
						}						

						if (this.form.PointMultiple&&this.form.PointMultiple!=1) {
							this.checked3 = true
						}
						if(this.form.PointMultiple==1){
							this.form.PointMultiple=null
						}
						if (this.form.CurrentAuthGivePoint) {
							this.package1 = true
						}

						if (this.form.CouponList.length) {
							this.form.CouponList.map(item => {
								item.Id = item.CouponId
							})
							this.package2 = true
							let dataStart = this.pageSizenum * (this.currentPagenum - 1);
							let dataEnd = this.pageSizenum * this.currentPagenum
							this.showCouponList = this.form.CouponList.slice(dataStart, dataEnd);
							this.TotalNum = this.form.CouponList.length
						}
						this.startProductData = JSON.parse(JSON.stringify(this.form.ProductSpecList));
						
						if (this.form.ProductSpecList.length) {
							this.form.ProductSpecList.map(item => {
								item.Id = item.ProductSpecId
								return item
							})

							this.package3 = true
							let dataStart = this.pageSizenum1 * (this.currentPagenum1 - 1);
							let dataEnd = this.pageSizenum1 * this.currentPagenum1
							this.showProductSpecList = this.form.ProductSpecList.slice(dataStart, dataEnd);
							this.TotalNum1 = this.form.ProductSpecList.length
							
						}
						this.origionGrowthValue = this.form.GrowthValue

					}

				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false
				}

			},


			// 删除优惠券商品
			delectContect(record, index) {
				this.form.CouponList = this.form.CouponList.filter(item => {
					return item.CouponId != record.CouponId
				})
				console.log(record, 999)

				this.TotalNum = this.form.CouponList.length

				let flagers = this.currentPagenum > Math.ceil(this.TotalNum / this.pageSizenum)
				if ((this.form.CouponList.length) % this.pageSizenum == 0 && this.currentPagenum > 1 && flagers) {
					this.currentPagenum = this.currentPagenum - 1;
				}

				let dataStart = this.pageSizenum * (this.currentPagenum - 1);
				let dataEnd = this.pageSizenum * this.currentPagenum
				this.showCouponList = this.form.CouponList.slice(dataStart, dataEnd);

				if (!this.form.CouponList.length) {
					this.$refs.compSelectProTable.clearSelection();
				}


			},

			// 确定选中的优惠券
			sureSelectcoup() {

				if (!this.multipleSelection.length) {
					this.$message({showClose: true,
						type: 'error',
						message: '请选择优惠券'
					});
					return
				}

				this.multipleSelection.map(item => {
					item.CouponId = item.Id
					this.form.CouponList.map(record => {
						if (record.Id == item.Id) {
							item.Count = record.Count
						}
					})
				})

				this.loading = true;
				this.form.CouponList = JSON.parse(JSON.stringify(this.multipleSelection));
				this.form.CouponList.map(item => {
					if (!item.Count) {
						item.Count = null
					}
				})

				if ((this.form.CouponList.length) % 5 == 0 && this.currentPagenum > 1) {
					this.currentPagenum = this.currentPagenum - 1
				}

				let dataStart = this.pageSizenum * (this.currentPagenum - 1);
				let dataEnd = this.pageSizenum * this.currentPagenum
				this.showCouponList = this.form.CouponList.slice(dataStart, dataEnd);
				this.TotalNum = this.form.CouponList.length;
				this.coupDialog = false;

				setTimeout(() => {
					this.loading = false
				}, 600)
			},
			// 切换显示条数
			handleSizeChangenum(val) {
				this.pageSizenum = val
				let dataStart = this.pageSizenum * (this.currentPagenum - 1)
				let dataEnd = this.pageSizenum * this.currentPagenum
				this.showCouponList = this.selectCoupDatas.slice(dataStart, dataEnd);

			},
			// 翻页
			handleCurrentChangenum(val) {
				this.currentPagenum = val;
				let dataStart = this.pageSizenum * (this.currentPagenum - 1);
				let dataEnd = this.pageSizenum * this.currentPagenum
				this.showCouponList = this.form.CouponList.slice(dataStart, dataEnd);
			},

			// 删除选中的商品

			delectContect1(record, index) {
				this.form.ProductSpecList = this.form.ProductSpecList.filter(item => {
					return item.Id != record.Id
				})

				this.TotalNum1 = this.form.ProductSpecList.length

				let flagers = this.currentPagenum1 > Math.ceil(this.TotalNum1 / this.pageSizenum1)
				if ((this.form.ProductSpecList.length) % this.pageSizenum1 == 0 && this.currentPagenum1 > 1 && flagers) {
					this.currentPagenum1 = this.currentPagenum1 - 1;
				}

				let dataStart = this.pageSizenum1 * (this.currentPagenum1 - 1);
				let dataEnd = this.pageSizenum1 * this.currentPagenum1
				this.showProductSpecList = this.form.ProductSpecList.slice(dataStart, dataEnd);


			},
			// 切换显示条数
			handleSizeChangenum1(val) {
				this.pageSizenum1 = val
				let dataStart = this.pageSizenum1 * (this.currentPagenum1 - 1);
				let dataEnd = this.pageSizenum1 * this.currentPagenum1
				this.showProductSpecList = this.form.ProductSpecList.slice(dataStart, dataEnd);

			},
			// 翻页
			handleCurrentChangenum1(val) {
				this.currentPagenum1 = val;
				let dataStart = this.pageSizenum1 * (this.currentPagenum1 - 1);
				let dataEnd = this.pageSizenum1 * this.currentPagenum1
				this.showProductSpecList = this.form.ProductSpecList.slice(dataStart, dataEnd);
			},



			getRowKeys(row) {
				return row.Id;
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.size = val;
				this.activitycoupoList()
			},
			// 翻页
			handleCurrentChange(val) {
				this.current = val;
				this.activitycoupoList()
			},

			//当前页全选
			handleSelectionChange(val) {
				this.selectNumber = val.length
				this.multipleSelection = val;

			},
			handleInput(e) {
				// 通过正则过滤小数点后1位
				e.target.value = (e.target.value.match(/^\d*(\.?\d{0,0})/g)[0]) || null
			},
			handleInput2(e) {
				// 通过正则过滤小数点后两位
				e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null

			},

			// 优惠券查询
			coupFilter() {
				this.current = 1;
				this.activitycoupoList()
			},
			// 选择优惠券
			selsectCoup() {
				this.activitycoupoList()
				this.coupDialog = true;
			},
			// 获取优惠券列表
			async activitycoupoList() {
				try {
					this.loading = true
					let data = {
						KeyWord: this.counpKeys,
						UseType: this.UseType,
						Skip: (this.current - 1) * this.size, // 略过数据
						Take: this.size, // 取的数据
					}

					let result = await activitycoupoList(data)
					this.tableDataList = result.Result.Results
					this.total = result.Result.Total


				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false

					if (this.form.CouponList.length) {
						this.$refs.compSelectProTable.clearSelection();
						this.form.CouponList.map(item => {
							this.$refs['compSelectProTable'].toggleRowSelection(item, true);
						})
					}

				}

			},
			// 选择商品
			handleSelectProDialog() {
				this.selectProDialog = true
			},
			// 获取商品
			getSelectList(data) {

				data.map(item => {
					this.form.ProductSpecList.map(record => {
						if (record.Id == item.Id) {
							item.Count = record.Count
						}
						
					 return record
					})
				})

				this.form.ProductSpecList = JSON.parse(JSON.stringify(data));
				let newProductcount=[]
				this.form.ProductSpecList.map(item => {	
					let obj={
						Count:item.Count?item.Count:null,
						Id:item.Id,
						ImgUrl:item.ImgUrl,
						ImgUrlComplete:item.ImgUrlComplete,
						IsDefault:item.IsDefault,
						IsOpen:item.IsOpen,
						Name:item.Name,
						ProductId:item.ProductId,
						ProductPrice:item.ProductPrice,
						ProductType:item.ProductType,
						ProductTypeValue:item.ProductTypeValue,
						Spec:item.Spec,
						Stock:item.Stock
					}
					newProductcount.push(obj)

				})
				this.form.ProductSpecList=newProductcount
				console.log(newProductcount,'最后选中的商品')

				if ((this.form.ProductSpecList.length) % 5 == 0 && this.currentPagenum1 > 1) {
					this.currentPagenum1 = this.currentPagenum1 - 1
				}

				let dataStart = this.pageSizenum1 * (this.currentPagenum1 - 1);
				let dataEnd = this.pageSizenum1 * this.currentPagenum1
				this.showProductSpecList = this.form.ProductSpecList.slice(dataStart, dataEnd);
				this.TotalNum1 = this.form.ProductSpecList.length

				this.selectProDialog = false;
			},
			saveBtn(formName) {				
				this.$refs[formName].validate(async (valid) => {

					if (valid) {
						

						if (!this.form.CouponList.length && this.package2) {
							this.$message({showClose: true,
								type: 'error',
								message: '请选择赠送的优惠券'
							});
							return
						}

						if (!this.form.ProductSpecList.length && this.package3) {
							this.$message({showClose: true,
								type: 'error',
								message: '请选择赠送的商品'
							});
							return
						} 

						if(this.form.GrowthValue!=this.origionGrowthValue){
							this.$confirm('您修改了当前级别所需成长值，保存成功后，将根据每个会员当前成长值自动升级或降级', '提示', {
								confirmButtonText: '确认修改',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(()=>{
									this.sureSave()
							}).catch(() => {
								this.$message({showClose: true,
									type: 'info',
									message: '已取消修改'
								});
							}).finally(() => {
							})
						}else{
							this.sureSave()
						}

					} else {
						console.log('error submit!!');
						return false;
					}
				});

			},
			async sureSave(){
				try {
					this.loading = true
					if (!this.package2) {
						this.form.CouponList = []
					}

					if (!this.package3) {
						this.form.ProductSpecList = []
					} else {
						this.lastSubmitProduct = []						
						this.form.ProductSpecList.map(item => {
							let obj = {
								ProductSpecId: item.Id,
								ProductId: item.ProductId,
								Count: item.Count?item.Count:0
							}							
							this.startProductData.map(record => {
								if (item.Id == record.ProductSpecId) {
									obj.Id = record.Id
								}
							})
							this.lastSubmitProduct.push(obj)
						})
						
					}
					
					if(!this.checked1){
						this.form.VipDiscount=10
					}
					if(!this.checked3){
						this.form.PointMultiple=1
					}

					// if (this.form.VipLevel == 1) {
					// 	if (this.uprankType == 0) {
					// 		this.form.IsUsePay = true;
					// 		this.form.IsUseGrowthValue = false
					// 	} else {
					// 		this.form.IsUseGrowthValue = true;
					// 		this.form.IsUsePay = false
					// 	}
					// } else {
					// 	this.form.IsUseGrowthValue = true;
					// 	this.form.IsUsePay = false
					// }
					
					this.form.IsUseGrowthValue = true;
					this.form.IsUsePay = false


					if (this.form.BackgroupType == 0) {
						this.form.BackgroupImg = ''
					}

					this.form.Id = this.carId;
					this.form.VipLevel = this.VipLevel
					
					let data={
						Id:this.form.Id,
						VipRoleName: this.form.VipRoleName,
						VipLevel:this.form.VipLevel,
						BackgroupType:this.form.BackgroupType,
						BackgroupColor: this.form.BackgroupColor,
						BackgroupImg: this.form.BackgroupImg,
						IsUsePay: this.form.IsUsePay,
						PayMoney: this.form.PayMoney,
						IsUseGrowthValue: this.form.IsUseGrowthValue,
						GrowthValue: this.form.GrowthValue,
						VipDiscount: this.form.VipDiscount,
						IsFreeExpress: this.form.IsFreeExpress,
						PointMultiple: this.form.PointMultiple,
						CurrentAuthGivePoint: this.form.CurrentAuthGivePoint,
						ProductSpecList: this.lastSubmitProduct,
						CouponList: this.form.CouponList
					}

					let result = await activitycoupoSave(data)

					if (result.IsSuccess) {
						this.$message({showClose: true,
							type: 'success',
							message: '操作成功'
						});
						this.$router.push({
							path: '/Customer/vipLevel'
						});

					}
				} catch (e) {
					console.log(e)
				} finally {
					setTimeout(() => {
						this.loading = false
					}, 600)
				}
			},
			//上传图片
			handleAvatarSuccessfu(res, file) {

				if (['image/png', 'image/jpeg', 'image/jpg', 'image/jpeg', 'image/gif', 'image/PNG', 'image/JPG', 'image/JPEG',
						'image/GIF'
					].indexOf(file.raw.type) == -1) {
					this.$message.error('请上传正确的图片格式');
					return false;
				}

				this.form.BackgroupImg = file.response[0]

			},
			cancelBtn() {
				this.$confirm('您还未保存编辑的数据，离开页面后，系统将不会为您保存数据，是否确认继续离开?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$router.push({
						path: '/Customer/vipLevel'
					});



				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消操作'
					});
				}).finally(() => {

				})

			}
		}
	}
</script>

<style lang="less">
	.formBox {
		.footerBar {
			position: fixed;
			bottom: 0;
			width: 100%;
			background: #fff;
			text-align: center;
			padding: 20px 0;
			z-index: 50;
		}

		.selectGoods {
			color: #409EFF;
			cursor: pointer;
		}

		.el-upload--picture-card {
			width: 135px !important;
			height: 30px !important;
			border: 1px solid #ddd;
			background: #fff !important;
			line-height: 29px !important;
		}

		.el-color-picker__empty,
		.el-color-picker__icon {
			left: 10000;
		}

		.el-color-picker--mini .el-color-picker__trigger {
			width: 140px !important;
			height: 35px !important;
		}

		.el-color-picker--mini .el-color-picker__empty,
		.el-color-picker--mini .el-color-picker__icon {
			display: none;
		}

		input[type=number]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		.number__input {
			border: 1px solid #ccc;
		}
	}
</style>
